<template>
	<div v-if="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="formRef" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="影院组织" name="organizationId">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;"
							@change="getAllCinemaList">
							 <a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{
							item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="所属影院" name="cinemaId">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name
							}}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="广告图名称" name="title">
						<a-input placeholder="请输入" v-model:value="formState.title"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="状态" name="isDisabled">
						<a-select placeholder="请选择" v-model:value="formState.isDisabled" style="width: 180px;">
							<a-select-option :value="0">已启用</a-select-option>
							<a-select-option :value="1">已禁用</a-select-option>
						</a-select>
					</a-form-item>

				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="['operation_advert_adWindow_add']" type="primary" @click="onAdd">新增</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="onReset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 1800 }"
					tableLayout="auto">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'jumpType'">
							<div v-if="record.jumpType">{{transJumpType(record.jumpType)}}</div>
							<div v-else>--</div>
						</template>

						<template v-if="column.key === 'imgUrl'">
							<a-image :width="50" :src="record.content" />
						</template>

						<template v-if="column.key === 'vaildTime'">
							<div>{{transTime(record.startTime)}} 至 {{transTime(record.endTime)}}</div>
						</template>

						<template v-if="column.key === 'isDisabled'">
							<a-tag v-if="record.isDisabled == 0" color="green">启用</a-tag>
							<a-tag v-else color="orange">禁用</a-tag>
						</template>

						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['operation_advert_adWindow_disabled']" @click="onDisabled(record)" v-if="record.isDisabled == 0">
											<a-menu-item> 禁用 </a-menu-item>
										</div>
										<div v-permission="['operation_advert_adWindow_open']" @click="onDisabled(record)" v-else>
											<a-menu-item> 启用 </a-menu-item>
										</div>
										<div v-permission="['operation_advert_adWindow_edit']" @click="onEdit(record)">
											<a-menu-item>
												编辑
											</a-menu-item>
										</div>
										<div v-permission="['operation_advert_adWindow_delete']" @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>

		</a-spin>
	</div>
	<temp v-else :isEdit="isEdit" :id="id" @back="onBack"></temp>
</template>

<script>
import temp from './temp.vue';
import {
	Icon
} from '@/components/icon/icon.js';
import { getBannerList, disableImg, enableImg, deleteImg } from '@/service/modules/advert.js';
import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
export default {
	components: {
		Icon,
		temp
	},
	data() {
		return {
			loading: false,
			formState: {
				organizationId: 0,
				cinemaId: 0
			},
			showModal: false,
			list: [],
			columns: [{
				title: '影院组织',
				dataIndex: 'organization',
			}, {
				title: '所属影院',
				dataIndex: 'cinema',
			}, {
				title: '广告名称',
				dataIndex: 'title'
			}, {
				title: '图片',
				key: 'imgUrl'
			}, {
				title: '跳转至',
				key: 'jumpType'
			}, {
				title: '有效期',
				key: 'vaildTime'
			}, {
				title: '排序',
				dataIndex: 'sort'
			}, {
				title: '状态',
				key: 'isDisabled'
			}, {
				title: '操作',
				key: 'action',
				width: 100,
				fixed: 'right'
			}],
			pagination: {
				showSizeChanger: true,
				showQuickJumper: true,
				size: "middle",
				showTotal: (total) => {
					return "共 " + total + " 条";
				},
				total: 0,
				current: 1,
				pageSize: 10,
				onChange: (page) => {
					this.pagination.current = page;
					this.getData();
				},
				onShowSizeChange: (page, pageSize) => {
					this.pagination.current = 1;
					this.pagination.pageSize = pageSize;
					this.getData();
				}
			},
			isEdit: false,
			id: 0,
			searchData: [],
			organizationList: [],
			cinemaAllList: [],
		}
	},
	created() {
		// this.getData();
		this.getOrganizationList();
	},
	methods: {
		onBack() {
			this.showModal = false;
			this.getData();
		},
		onSearch() {
			this.pagination.current = 1;
			// //this.pagination.pageSize = 10;
			this.searchData = JSON.parse(JSON.stringify(this.formState));
			this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
			this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
			this.getData();
		},
		onReset() {
			this.$refs.formRef.resetFields();
			if (this.organizationList.length) {
				this.formState.organizationId = this.organizationList[0].id;
				this.getAllCinemaList(this.formState.organizationId);
			}
			this.onSearch();
		},
		async getData() {
			const postData = {
				page: this.pagination.current,
				pageSize: this.pagination.pageSize,
				type: 6,// 1轮播图 6弹窗
				...this.searchData,
			}
			this.loading = true;
			try {
				let res = await getBannerList(postData);
				this.loading = false;
				if (res.code == 200) {
					this.list = res.data.list;
					this.pagination.total = res.data.totalCount;
				}
			} catch (e) {
				this.loading = false;
			}
		},
		onAdd() {
			this.id = 0;
			this.isEdit = false;
			this.showModal = true;
		},
		onEdit(val) {
			this.id = val.id;
			this.isEdit = true;
			this.showModal = true;
		},
		onDelete(val) {
			this.$confirm({
				title: 'warning',
				content: '正在进行删除操作',
				okText: '确定',
				cancelText: '取消',
				onOk: () => {
					this.deleteImg(val.id);
				}
			})
		},
		onDisabled(val) {
			const textObj = {
				1: '启用',
				0: '禁用'
			}
			let text = textObj[val.isDisabled];
			this.$confirm({
				title: 'warning',
				content: '正在进行' + text + '操作',
				okText: '确定',
				cancleText: '取消',
				onOk: () => {
					if (val.isDisabled == 0) {
						this.disableImg(val.id);
					} else {
						this.enableImg(val.id);
					}
				}
			})
		},
		async disableImg(id) {
			let res = await disableImg({
				id
			})
			if (res.code == 200) {
				this.getData();
				this.$message.success('禁用成功');
			}
		},
		async enableImg(id) {
			let res = await enableImg({
				id
			})
			if (res.code == 200) {
				this.getData();
				this.$message.success('启用成功');
			}
		},
		async deleteImg(id) {
			let res = await deleteImg({
				id
			})
			if (res.code == 200) {
				this.getData();
				this.$message.success('删除成功');
			}
		},
		async getOrganizationList() {
			this.loading = true;
			try {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
					if (this.organizationList.length) {
						this.formState.organizationId = this.organizationList[0].id;
						this.getAllCinemaList(this.formState.organizationId);
					}
					this.onSearch();
				} else {
					this.loading = false;
				}
			} catch(e) {
				this.loading = false;
			}
		},
		async getAllCinemaList(organizationId) {
			this.cinemaAllList = [];
			this.formState.cinemaId = '';
			let ret = await getCinemaList({
				page: 1,
				pageSize: 999999,
				organizationId: organizationId ? organizationId : undefined
			})
			if (ret.code === 200) {
				this.cinemaAllList = ret.data.list;
			}
		},
		transJumpType(type) {
			const jumpType = {
				1: '无',
				2: '影片',
				3: '指定商品',
				4: '商城首页',
				5: '指定卖品',
				10: '卖品页',
				11: '成为分销商页面'
			}
			return jumpType[type];
		}
	}
}
</script>

<style scoped>
.ui-form__item {
	margin-right: 30px;
}
</style>
