<template>
	<div>
		<Header :title="isEdit ? '修改广告图' : '新增广告图'" @back="onBack"></Header>
		<a-form style="margin-top: 30px;" ref="formRef" scrollToFirstError name="form" :labelCol="{span: 6, xxl: 5}"
			:wrapperCol="{span: 14, xxl: 13 }"  @finish="onSubmit" :model="formState">
			
            <a-form-item label="影院组织" name="organizationId" :rules="[{required: true, message: '必选项不允许为空'}]">
                <a-select v-model:value="formState.organizationId" placeholder="请选择影院组织" @change="(id) => getAllCinemaList(id, true)">
                    <a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
                </a-select>
            </a-form-item>
            
            <a-form-item label="所属影院" name="cinemaId" :rules="[{required: true, message: '必选项不允许为空'}]">
                <a-select v-model:value="formState.cinemaId" placeholder="请选择所属影院" @change="onClear">
					<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
                </a-select>
            </a-form-item>
            
            <a-form-item label="广告图名称" name="title" :rules="[{required: true, message: '必填项不允许为空'}]">
                <a-input v-model:value="formState.title" placeholder="请输入广告图名称"></a-input>
            </a-form-item>

            <!-- <a-form-item label="广告标签" :rules="[{required: true, message: '必填项不允许为空'}]">
                <a-checkbox-group>
                    <a-checkbox :value="0">新客</a-checkbox>
                    <a-checkbox :value="1">标签</a-checkbox>
                    <a-checkbox :value="2">常规</a-checkbox>
                </a-checkbox-group>
            </a-form-item> -->
			
			<a-form-item label="展示位置" name="location" :rules="[{required: true, message: '必填项不允许为空'}]">
			    <a-radio-group v-model:value="formState.location" name="radioGroup" @change="formState.type = 1;">
			        <a-radio :value="1">商城页</a-radio>
			        <a-radio :value="2">购票页</a-radio>
					<a-radio :value="3">购票订单详情页</a-radio>
			    </a-radio-group>
			</a-form-item>

            <a-form-item label="跳转类型" name="jumpType" :rules="[{required: true, message: '必填项不允许为空'}]">
                <a-radio-group v-model:value="formState.jumpType" name="radioGroup" @change="onClear">
                    <a-radio :value="1">无跳转</a-radio>
                    <!-- <a-radio :value="2">影片</a-radio> -->
					<a-radio :value="4">商城页</a-radio>
                    <a-radio :value="3">指定商品</a-radio>
                    <a-radio :value="10">卖品页</a-radio>
                    <a-radio :value="5">指定卖品</a-radio>
					 <a-radio :value="11">成为分销商页面</a-radio>
                </a-radio-group>
            </a-form-item>

            <a-form-item v-if="formState.jumpType == 2 || formState.jumpType == 3 || formState.jumpType == 5" label="跳转位置" :rules="[{required: true, message: '必填项不允许为空'}]">
                <div v-if="formState.cinemaId">
                    <ChooseMovie v-model:value="formState.filmList" :id="formState.cinemaId" v-if="formState.jumpType == 2"></ChooseMovie>
                    <ChooseGoods v-model:value="formState.goodsList" :id="formState.cinemaId" v-if="formState.jumpType == 3"></ChooseGoods>
                    <div v-if="formState.jumpType == 5" style="display: flex;">
						<span><snackModal v-model:value="formState.snackList" :id="formState.cinemaId" type="radio"></snackModal></span>
						<span style="margin-top: 5px;margin-left: 8px;">已选择{{ formState.snackList.length || 0 }}件卖品</span>
					</div>
                </div>
                <a-button v-else disabled>请选择所属影院</a-button>
            </a-form-item>

            <a-form-item label="广告图" :rules="[{required: true, message: '必填项不允许为空'}]" extra="建议图片比例：624 : 728">
                <a-upload
                    v-model:file-list="fileList"
                    list-type="picture-card"
                    action="/admin/ajaxUpload.do"
                    :before-upload="beforeUpload"
                    @change="handleChange"
                    @preview="handlePreview"
                >
                    <div v-if="fileList.length < 1">
                        <Icon icon="PlusOutlined"></Icon>
                        <div style="margin-top: 8px">Upload</div>
                    </div>
                </a-upload>
                <a-modal :visible="previewVisible" :maskClosable="false" :footer="null" @cancel="handleCancel">
                    <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
            </a-form-item>

            <a-form-item label="排序" extra="数字越大，排序越前，如果同级，最新创建的优先级大">
                <a-input-number id="inputNumber" defaultValue="0" v-model:value="formState.sort" :min="0" />
            </a-form-item>
            
            <a-form-item label="有效期" name="validTime" :rules="[{required: true, message: '必选项不允许为空' }]">
                <a-range-picker v-model:value="formState.validTime" @change="onVaildTime"/>
            </a-form-item>

            <a-form-item label="是否启用" :rules="[{required: true, message: '必选项不允许为空'}]">
                <a-switch v-model:checked="formState.isDisabled" checked-children="是" un-checked-children="否"></a-switch>
            </a-form-item>
            
            <a-form-item label="是否允许不再提醒" :rules="[{required: true, message: '必填项不允许为空'}]">
                <a-switch v-model:checked="formState.isRemind" checked-children="允许" un-checked-children="不允许"></a-switch>
            </a-form-item>

            <a-form-item label="是否推送给新客" :rules="[{required: true, message: '必填项不允许为空'}]">
                <a-switch v-model:checked="formState.newCustomers" checked-children="是" un-checked-children="否"></a-switch>
            </a-form-item>

			<a-row>
				<a-col :span="24" style="margin-top: 20px;text-align: center;">
					<a-button type="primary" html-type="submit">提交</a-button>
					<a-button style="margin-left: 20px;" @click="onBack">返回</a-button>
				</a-col>
			</a-row>
		</a-form>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
    import { Icon } from '@/components/icon/icon.js';
	import {getDetail,saveImg,updateImg} from '@/service/modules/advert.js';
    import moment from 'moment';
    import ChooseMovie from '@/components/chooseMovie/chooseMovie.vue';
    import ChooseGoods from '@/components/chooseGoods/chooseGoods.vue';
    import snackModal from '@/components/snackSelect/index.vue';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';

	export default {
		components: { Header, Icon, ChooseMovie, ChooseGoods, snackModal },
		props: {
			isEdit: {
				type: Boolean,
				default: true
			},
			id: {
				type: Number,
				default: 0
			},
		},
		created() {
            this.getOrganizationList();
			// this.getAllCinemaList();
			if(this.isEdit && this.id) {
				this.getData();
			}
		},
		data() {
			return {
                fileList: [],
                formState: {
                    location: 1,
                    validTime: [],
                    jumpType: 1,
                },
                cinemaAllList: [],
                organizationList: [],
                previewVisible: false,
                previewImage: '',
			}
		},
		methods: {
			onBack() {
				this.$emit('back');
			},
			async getData() {
                
                let {data} = await getDetail({
                    id: this.id,
                })
                this.formState = data;
                this.formState.isRemind = this.formState.isRemind == 1 ? true : false;
				this.formState.newCustomers = this.formState.newCustomers ? true :false;
                this.formState.isDisabled = this.formState.isDisabled ? false : true;
                this.formState.validTime = [moment(this.formState.startTime*1000), moment(this.formState.endTime*1000)];
                if(this.formState.jumpType == 2){
                    this.formState.filmList = [this.formState.jumpId];
                }
                if(this.formState.jumpType == 3){
                    this.formState.goodsList = [this.formState.jumpId];
                }
                if(this.formState.jumpType == 5) {
                    this.formState.snackList = [this.formState.jumpId];
                }
                if(data.content){
                    this.fileList = data.content.split(',').map(item=>{
                        return {
                            url: item,
                        }
                    })
                }
				this.getAllCinemaList(data.organizationId, false);
            },
			onSubmit() {
                this.$refs.formRef.validateFields().then(async () => {
                    let startTime = moment(this.formState.validTime[0].startOf('day')).unix();
                    let endTime = moment(this.formState.validTime[1].endOf('day')).unix();
                    if(!this.fileList.length){
                        this.$message.warn('请上传轮播图');
                        return;
                    }
                    if(!startTime || !endTime){
                        this.$message.warn('请选择有效期');
                        return;
                    }
                    if(this.formState.goodsList && this.formState.goodsList.length > 1){
                        return this.$message.warn('最多选择1件商品');
                    }
                    if(this.formState.filmList && this.formState.filmList.length > 1){
                        return this.$message.warn('最多选择1部影片');
                    }
					if(this.formState.snackList && this.formState.snackList.length > 1){
					    return this.$message.warn('最多选择1件卖品');
					}
                    const postData = JSON.parse(JSON.stringify(this.formState));
                    if(postData.goodsList && postData.goodsList.length){
                        postData.jumpId = postData.goodsList.join(',');
                        delete postData.goodsList;
                    }
                    if(postData.filmList && postData.filmList.length){
                        postData.jumpId = postData.filmList.join(',');
                        delete postData.filmList;
                    }
                    if(postData.snackList && postData.snackList.length){
                        postData.jumpId = postData.snackList.join(',');
                        delete postData.snackList;
                    }
					//console.log(postData.jumpId)
					if ((postData.jumpType === 2 || postData.jumpType === 3 || postData.jumpType === 5) && !Number(postData.jumpId)) {
						return this.$message.warn('请选择跳转位置');
					}
                    delete postData.validTime;
                    postData.startTime = startTime;
                    postData.endTime = endTime;
                    postData.type = 6;
                    postData.isRemind = this.formState.isRemind == true ? 1 : 0;
                    postData.isDisabled = this.formState.isDisabled ? 0 : 1;
					postData.newCustomers = this.formState.newCustomers ? 1 : 0;
                    const imgList = [];
                    this.fileList.forEach(item=>{
                        imgList.push(item.url);
                    });
                    postData.content = imgList.join(',');
                    //console.log('postData',postData);
                    let res;
                    if(this.id){
                        postData.id = this.id;
                        res = await updateImg(postData);
                    }else{
                        res = await saveImg(postData);
                    }
                    if(res.code == 200){
                        this.$message.success('操作成功');
                        this.onBack();
                    }
				})
            },
            beforeUpload(file) {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                    this.$message.error('上传文件格式不正确');
                }
                const isLt10M = file.size / 1024 / 1024 < 10;
                if (!isLt10M) {
                this.$message.error('上传文件太大了');
                }
                return isJpgOrPng && isLt10M;
            },
            handleChange(info) {
                if (info.file.status === 'done') {
                    this.fileList = info.fileList;
                    const $fileList = [...this.fileList];
                    this.fileList = $fileList.map(file => {
                        if (file.response) {
                            file.url = file.response.data.imgUrl;
                        }
                        return file;
                    });
                }
            },
            onVaildTime(value){
                this.formState.validTime = value;
            },
            async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
				}
			},
			async getAllCinemaList(organizationId, isClear) {
				if (isClear) {
					this.cinemaAllList = [];
					this.formState.cinemaId = undefined;
					this.onClear();
				}
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onClear() {
				this.formState.filmList = [];
				this.formState.goodsList = [];
				this.formState.snackList = [];
			},
            handlePreview(file) {
                this.previewImage = file.url || file.thumbUrl;
                this.previewVisible = true;
            },
            handleCancel() {
                this.previewVisible = false;
            },
		}
	}
</script>
